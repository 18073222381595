export const productsPerPartner = [

        //OZOOCA
        {
            partnerId: "b703ce9f-9f14-4e2b-83ad-3fe37c3ecb87",
            productId: "e690fb58-96f8-4088-bce9-e39c88d04299",
            shortCode: "24972",
            activationkeyWord:"G5"
        },
        {
            partnerId: "b703ce9f-9f14-4e2b-83ad-3fe37c3ecb87",
            productId: "24a58d1c-5f1c-443c-8136-94a446c2969ed",
            shortCode: "24972",
            activationkeyWord:"G10"
        },
        {
            partnerId: "b703ce9f-9f14-4e2b-83ad-3fe37c3ecb87",
            productId: "87528f3a-b56b-428e-a37f-feacc08597e6",
            shortCode: "24972",
            activationkeyWord:"G15"
        },
        //TECH BUILD GLOBAL
        {
            partnerId: "128848f3-89c9-4af7-ac42-5a0775954141",
            productId: "470249e5-140e-45fb-b7bb-347b26f48b5b",
            shortCode: "24974",
            activationkeyWord:"GM5"
        },
        {
            partnerId: "128848f3-89c9-4af7-ac42-5a0775954141",
            productId: "a2d4a436-b0b8-490b-a563-2889d4d207b2",
            shortCode: "24974",
            activationkeyWord:"GM10"
        },
        {
            partnerId: "128848f3-89c9-4af7-ac42-5a0775954141",
            productId: "c7427619-a708-40cc-97cb-ce916cb5cbdf",
            shortCode: "24974",
            activationkeyWord:"GM15"
        },
    
]