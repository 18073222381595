// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `  ::-webkit-scrollbar {
    display: none;
  }
  ::-webkit-scrollbar-thumb {
    background: transparent;
    display: none !important;
  }
  ::-webkit-scrollbar-track {
  background: transparent;
  display: none !important;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/styles/games.css"],"names":[],"mappings":"EAAE;IACE,aAAa;EACf;EACA;IACE,uBAAuB;IACvB,wBAAwB;EAC1B;EACA;EACA,uBAAuB;EACvB,wBAAwB;EACxB","sourcesContent":["  ::-webkit-scrollbar {\n    display: none;\n  }\n  ::-webkit-scrollbar-thumb {\n    background: transparent;\n    display: none !important;\n  }\n  ::-webkit-scrollbar-track {\n  background: transparent;\n  display: none !important;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
