import React from 'react'

const ProductCard = ({ banner, category, title, description }) => {
    return (
        <div>
            <div class="wsk-cp-product">
                <div class="wsk-cp-img">
                    <img src={banner} class="img-responsive" />
                </div>
                <div class="wsk-cp-text">
                    <div class="category">
                        <span>{category}</span>
                    </div>
                    <div class="title-product">
                        <h3>{title}</h3>
                    </div>
                    <div class="description-prod">
                        <p>{description}</p>
                    </div>
                    {/* <div class="card-footer">
                        <div class="wcf-left"><span class="price">Rp500.000</span></div>
                        <div class="wcf-right"><a href="#" class="buy-btn"><i class="zmdi zmdi-shopping-basket"></i></a></div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default ProductCard