import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Games from './components/Games';
import ProductCard from './components/ProductCard';
import NewGame from './components/NewGame';
import Details from './components/Details';


import './assets/css/fontawesome.css'
import './assets/css/templatemo-cyborg-gaming.css'
import './assets/css/owl.css'
import './assets/css/animate.css'
import './vendor/bootstrap/css/bootstrap.min.css'

function App() {

  return (

    <Router>
      <Routes>
        <Route exact path="/" element={<NewGame />} />
        <Route exact path="/game-description" element={<Details />} />
        <Route exact path='/card' element={<ProductCard />} />
      </Routes>
    </Router>
  );
}

export default App;


