import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Games from './Games';
import Box from '@mui/material/Box';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import gameHeroBanner from '../gameportalImg.png';

import logoutIcon from '../logout.png';

import Nav from './Nav';

function Home() {
  const navigate = useNavigate();
  const [logoutMessage, setLogoutMessage] = useState('');
  const handleLogout = () => {
    // Remove msisdn from sessionStorage
    sessionStorage.removeItem('msisdn');
    sessionStorage.removeItem('url');
    // Set the logout message
    setLogoutMessage('You have been logged out successfully!');
    // Navigate to the root path
    navigate('/');

    // Clear the logout message after 4 seconds
    setTimeout(() => {
      setLogoutMessage('');
    }, 3000);
  };

  return (
    <>
      <div
        style={{ position: "sticky", top: "0", zIndex: "999" }}
      >
        <Nav />
      </div>
      <div className="hero">
        <div
          style={{
            backgroundImage: `url(${gameHeroBanner})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            width: '100%',
            height: '250px',
            position: 'relative',
          }}
        >

          <img src={logoutIcon} alt=""
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              color: '#fff',
              fontSize: '32px', // Increase the font size to adjust the icon size
              height: "35px",
              cursor: "pointer",
              opacity: sessionStorage.getItem('msisdn') ? "1" : "0"
            }}

            onClick={handleLogout}
          />
          <IconButton
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              color: '#fff',
              fontSize: '32px', // Increase the font size to adjust the icon size
            }}
            onClick={handleLogout}
          >
            {/* <LogoutIcon /> */}
          </IconButton>
        </div>
        {logoutMessage && (
          <p
            style={{
              textAlign: 'center',
              color: '#29B43F',
              fontWeight: 'bold',
            }}
          >
            {logoutMessage}
          </p>
        )}

        <Box
          mt={2}
          maxWidth="1200px"
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '0 auto',
          }}
        >
          <Games />
        </Box>

      </div>
    </>
  );

}

export default Home;


