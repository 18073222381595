import React, { useEffect } from 'react'

import '../styles/nav.css';

import safLogo from '../saf-logo3.svg';
import logo from '../assets/images/libro-pink-logo.svg';
import profileImage from '../assets/images/profile-header.jpg';


const Nav = () => {

    return (
        <div>
            <header className="header-area header-sticky">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <nav className="main-nav">
                                {/* ***** Logo Start ***** */}
                                <a href="/" className="logo">
                                    <img src={logo} alt="" />
                                </a>
                                {/* ***** Logo End ***** */}
                                {/* ***** Search End ***** */}
                                <div className="search-input">
                                    <form id="search" action="#">
                                        <input type="text" placeholder="Type Something" id='searchText' name="searchKeyword" />
                                        <i className="fa fa-search"></i>
                                    </form>
                                </div>
                                {/* ***** Search End ***** */}
                                {/* ***** Menu Start ***** */}
                                <ul className="nav">
                                    {/* <li><a href="index.html" className="active">Home</a></li>
                                        <li><a href="details.html">Details</a></li> */}
                                    <li><a href="#">Profile <img src={profileImage} alt="" /></a></li>
                                </ul>
                                <a className='menu-trigger'>
                                    <span>Menu</span>
                                </a>
                                {/* ***** Menu End ***** */}
                            </nav>
                        </div>
                    </div>
                </div>
            </header>

        </div>
    )
}

export default Nav