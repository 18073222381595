import React, { useEffect, useState } from 'react'


import '../assets/css/fontawesome.css'
import '../assets/css/templatemo-cyborg-gaming.css'
import '../assets/css/owl.css'
import '../assets/css/animate.css'
import '../vendor/bootstrap/css/bootstrap.min.css'

import right_img from '../assets/images/feature-right.jpg'
import left_img from '../assets/images/feature-left.jpg'

import detailsBanner1 from '../assets/images/details-01.jpg'
import game1 from '../assets/images/game-01.jpg'
import Nav from './Nav'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { Box, Button, Modal, Stack, TextField, Typography } from '@mui/material'
import { Toaster, toast } from 'react-hot-toast'
import { productsPerPartner } from '../Common'




const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column"

};


const Details = () => {

    const history = useLocation();
    const [changedGame, setChangedGame] = useState(false);
    const [msisdn, setMsisdn] = useState("");
    const [msisdnError, setMsisdnError] = useState(true);

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    if (sessionStorage.getItem("gameId") == null || sessionStorage.getItem("games") == null || sessionStorage.getItem("category") == null) {
        window.location.href = "/";
    }


    //scroll to top
    useEffect(() => {
        window.scrollTo(0, 0);

    }, [changedGame]);


    const handleClickDetails = (game) => {
        sessionStorage.setItem("gameId", game.id);
        sessionStorage.setItem("category", game.category);
        setChangedGame(!changedGame)

    }


    const handleMsisdn = (msisdn) => {
        setMsisdn(msisdn)
        if (msisdn.length >= 9 && msisdn.length < 13) {
            setMsisdnError(false);
        } else {
            setMsisdnError(true);
        }
    }

    const handleSubscribe = () => {
        if (msisdn.length >= 9 && msisdn.length < 13) {
            // alert("Thank you. Subscription is under way!");
            return true
        }else{
            return  false
        }
    }


    const[activateSms, setActivateSms]=useState("");

    useEffect(()=>{
        // const urlParams = new URLSearchParams(window.location.search);
        // console.log(urlParams)

        // //get marketerId/ partnerId
        // const partnerId = urlParams.get('mktid');

        // //get productId
        // const productId = urlParams.get('prodId');
        const productId = localStorage.getItem("productId");
        const partnerId = localStorage.getItem("partnerId");


        if(productId == "null" || partnerId == 'null'){
            toast.error("Parameter missing");
        }else{
            productsPerPartner.filter(p=> p.partnerId == partnerId && p.productId == productId).map(prod=>{
                setActivateSms( `sms:+${prod.shortCode}?body=${prod.activationkeyWord}`);
            })
        }

        console.log("productId: " + productId + "   marketerID:  " + partnerId);
    },[])

    console.log("activation sms" + activateSms);


    return (
        <div
            style={{ background: "#1f2122" }}
        >
            <Nav />

            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="page-content">


                            {/* <!-- ***** Featured Start ***** --> */}
                            {/* <div classNameName="row">
                                <div className="col-lg-12">
                                    <div className="feature-banner header-text">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <img src={left_img} alt="" style={{ borderRadius: "23px" }} />
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="thumb">
                                                    <img src={right_img} alt="" style={{ borderRadius: "23px" }} />
                                                    <a href="https://www.youtube.com/watch?v=r1b03uKWk_M" target="_blank"><i
                                                        className="fa fa-play"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            {/* <!-- ***** Featured End ***** --> */}


                            {/* <!-- ***** Details Start ***** --> */}
                            {JSON.parse(sessionStorage.getItem("games"))
                                .filter(g => g.id == sessionStorage.getItem("gameId"))
                                .map(game => {
                                    //initialize category
                                    return (

                                        <div className="game-details">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <h2>{game.name}</h2>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="content">
                                                        <div className="row">

                                                            <div className="col-lg-4 detail-banner">
                                                                <img src={game.banner} alt="" style={{ borderRadius: "23px", marginBottom: "30px" }} />
                                                            </div>
                                                            <div className="col-lg-4 detail-banner">
                                                                <img src={game.banner} alt="" style={{ borderRadius: "23px", marginBottom: "30px" }} />
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <img src={game.banner} alt="" style={{ borderRadius: "23px", marginBottom: "30px" }} />
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <p>{game.description}</p>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <div className="main-border-button"
                                                                    onClick={() => setOpen(true)}
                                                                >
                                                                    <a href="#">Play {game.name} Now!</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )
                                })}
                            {/* <!-- ***** Details End ***** --> */}


                            {/* related games start  */}
                            <div className="other-games">
                                <div className="row">

                                    <div className="col-lg-12">
                                        <div className="heading-section">
                                            <h4><em>Other Related</em> Games</h4>
                                        </div>
                                    </div>

                                    {JSON.parse(sessionStorage.getItem("games"))
                                        .filter(g => g.category == sessionStorage.getItem("category"))
                                        .map(game => {
                                            return (
                                                <div className="col-lg-6"
                                                    onClick={() => handleClickDetails(game)}>
                                                    {/* <a href="/game-description"> */}
                                                    <div className="item">
                                                        <img src={game.banner} alt="" className="templatemo-item" />
                                                        <h4>{game.name}</h4><span>{game.category}</span>
                                                        <ul>
                                                            {/* <li><i className="fa fa-star"></i> 4.8</li>
                                                                <li><i className="fa fa-download"></i> 2.3M</li> */}
                                                        </ul>
                                                    </div>
                                                    {/* </a> */}
                                                </div>

                                            )
                                        })}

                                </div>
                            </div>
                            {/* related games end*/}



                        </div>
                    </div>
                </div>
            </div>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <TextField
                        required
                        id="outlined-required"
                        label="Phone Number"
                        placeholder="25470000000"
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        autoFocus={true}
                        error={msisdnError}
                        onChange={(e) => handleMsisdn(e.target.value)}
                        sx={{ width: "100%" }}
                    />
                   
                    <a style={{ color: "#fff" }}
                        href={activateSms != "" ? activateSms :""}>
                        <Button variant="contained" sx={{ margin: "auto", width: "100%", mt: 3 }}>
                            SUBSCRIBE
                        </Button>
                    </a>

                </Box>



                {/* <Stack spacing={2} direction="row">
                    <Button variant="text">Text</Button>
                    <Button variant="contained">Contained</Button>
                    <Button variant="outlined">Outlined</Button>
                </Stack> */}
            </Modal>

            <Toaster />



            <script src="../vendor/jquery/jquery.min.js"></script>
            <script src="../vendor/bootstrap/js/bootstrap.min.js"></script>

            <script src="../assets/js/isotope.min.js"></script>
            <script src="../assets/js/owl-carousel.js"></script>
            <script src="../assets/js/tabs.js"></script>
            <script src="../assets/js/popup.js"></script>
            <script src="../assets/js/custom.js"></script>


        </div>
    )
}

export default Details